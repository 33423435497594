<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams />
    <SnippetCallbacks />
    <Tagging :consent="snippetConfig.consent" />
    <UrlHandler />
    <transition name="none">
      <RouterView />
    </transition>
  </div>
</template>

<script lang="ts">
  import {
    SnippetCallbacks,
    SnippetConfig,
    UrlParams,
    XEvent,
    XOn,
    XProvide
  } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { InternalSearchRequest, InternalSearchResponse } from '@empathyco/x-components/search';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';
  import { useDevice } from './composables/use-device.composable';

  @Component({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler
    }
  })
  export default class App extends Vue {
    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;
    protected device = useDevice();

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    /** Backroom implementation */
    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      window.wysiwyg?.open();
    }
    @XOn(['UserClickedCloseX'])
    close(): void {
      window.wysiwyg?.close();
    }
    @XOn(['UserAcceptedAQuery'])
    async goToLoginWysiwyg(query: string): Promise<void> {
      if (/^::\s*login/.test(query)) {
        await window.wysiwyg?.goToLogin();
      }
    }
    @XOn(['SearchRequestChanged'])
    onSearchRequestChanged(payload: InternalSearchRequest | null): void {
      window.wysiwyg?.setContext({ query: payload?.query, spellcheckedQuery: undefined });
    }
    @XOn(['SearchResponseChanged'])
    onSearchResponseChanged(payload: InternalSearchResponse): void {
      if (payload.spellcheck) {
        window.wysiwyg?.setContext({ spellcheckedQuery: payload.spellcheck });
      }
    }
    @XOn(['ParamsLoadedFromUrl'])
    async requestAuthWysiwyg(payload: UrlParams): Promise<void> {
      try {
        if (window.wysiwyg) {
          await window.wysiwyg?.requestAuth();
          window.InterfaceX?.search();
          window.wysiwyg?.setContext({ query: payload.query });
        }
      } catch (_) {
        // No error handling
      }
    }

    reloadSearch(): void {
      this.$x.emit('ReloadSearchRequested');
    }
    mounted(): void {
      document.addEventListener('wysiwyg:reloadSearch', () => this.reloadSearch());
    }
    beforeDestroy(): void {
      document.removeEventListener('wysiwyg:reloadSearch', () => this.reloadSearch());
    }

    /** End */

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return this.snippetConfig.queriesPreview;
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }

    @Watch('device.deviceName')
    syncDevice(deviceName: string): void {
      this.$setLocaleDevice(deviceName);
    }

    beforeMount(): void {
      const params = new URLSearchParams(location.search);
      const storeParam = params.get('store');
      let areaParam = '';

      if (location.pathname.startsWith('/amagijon')) {
        areaParam = 'Asturias';
        this.$x.emit('UserChangedExtraParams', { area: areaParam });
      }

      if (location.pathname.startsWith('/amadrid')) {
        areaParam = 'Comunidad de Madrid';
        this.$x.emit('UserChangedExtraParams', { area: areaParam });
      }

      this.$x.emit('UserPickedParams' as XEvent, {
        area: areaParam || undefined,
        name: storeParam ?? undefined
      });

      if (storeParam) {
        this.$x.emit('GetStorePreview' as XEvent, { store: storeParam });
      }
    }
  }
</script>

<style scoped>
  .x-modal::v-deep .x-modal__content {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
  }
</style>
<style lang="scss">
  *:not(.x-keyboard-navigation *) {
    outline: none;
  }

  .x-banner,
  .x-promoted {
    &__title {
      display: none;
    }
  }

  .none-enter-active,
  .none-leave-active {
    transition: none !important;
  }
  .none-enter, .none-leave-to /* .none-leave-active in <2.1.8 */ {
    opacity: 1 !important;
  }
</style>

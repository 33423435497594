import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { Dictionary } from '@empathyco/x-utils';
import { getTaggingInfoFromUrl, searchRequestMapper } from '@empathyco/x-adapter-platform';
import { getEnvironment } from '../../utils/environment';
import { getEncodedSignature } from '../../utils/encryption';
import { MyMotiveStoreResult } from '../models/entitites/result';
import { staticLogos } from './store-info';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStoreResultsEndpoint(): string {
  const storeResultsPath = 'query/mymotivemarketplace/searchbystore';

  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_SEARCH_ENDPOINT_PROD,
    staging: process.env.VUE_APP_SEARCH_ENDPOINT_STAGING
  };

  const env = getEnvironment();

  return `${endpoint[env]}${storeResultsPath}`;
}

export const storeResults = endpointAdapterFactory<any, any>({
  endpoint: getStoreResultsEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    storeResults: response => storeResultsMapper(response.catalog.content as MyMotiveStoreResult[]),
    productsPerShop: response => extractProductsPerShop(response)
  }),
  requestMapper: searchRequestMapper
});

/**
 * Extracts products per shop from the response object.
 *
 * @param response - The response object containing facets data.
 * @returns A dictionary where keys are store names and values are counts.
 */
function extractProductsPerShop(response: any): Record<string, number> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const storeFacet = response.catalog.facets.find((facet: any) => facet.facet === 'store');

  if (!storeFacet) {
    return {};
  }

  const productsPerShop: Record<string, number> = {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  storeFacet.values.forEach((value: any) => {
    productsPerShop[value.id] = value.count;
  });

  return productsPerShop;
}

/**
 * Auxiliar function to get the img proxy service URL.
 *
 * @returns The URL of the images proxy depending on the current app
 * environment (staging, prod and test by default).
 */
function getProxyServiceURL(): string {
  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_IMAGE_PROXY_SERVICE_PROD,
    staging: process.env.VUE_APP_IMAGE_PROXY_SERVICE_STAGING
  };

  const env = getEnvironment();

  return endpoint[env];
}

/**
 * Map result image by prepending the proxy service URL .
 *
 * @param image - The image to map.
 * @returns A mapped images.
 */
function mapImage(image: string): string {
  const proxyURL = getProxyServiceURL();
  return proxyURL + getEncodedSignature(image);
}

/**
 * Maps the store results from the response.
 *
 * @param results - The results array.
 * @returns The mapped store results.
 */
function storeResultsMapper(results: MyMotiveStoreResult[]): any {
  results.forEach(store => {
    store.products.forEach(product => {
      if (product.images) {
        product.images = product.images.map((image: string) => mapImage(image));
      }
      if (product.logoUrl || staticLogos[product.store.toString()]) {
        if (staticLogos[product.store.toString()]) {
          product.logoUrl = `logos/${staticLogos[product.store.toString()]}`;
        } else {
          product.logoUrl = mapImage(product.logoUrl);
        }
      }

      if (typeof product.price === 'number') {
        product.price = {
          value: product.price,
          originalValue: product.previousPrice,
          hasDiscount: !!product.previousPrice && product.previousPrice > product.price
        };
      }
      if (!product.shortDescription) {
        product.shortDescription = product.description ? product.description.split('. ')[0] : '';
      }

      if (product.tagging) {
        product.tagging = {
          add2cart: product.tagging.add2cart
            ? getTaggingInfoFromUrl(product.tagging.add2cart as string)
            : '',
          checkout: product.tagging.checkout
            ? getTaggingInfoFromUrl(product.tagging.checkout as string)
            : '',
          click: product.tagging.click
            ? getTaggingInfoFromUrl(product.tagging.click as string)
            : '',
          displayClick: product.tagging.displayClick
            ? getTaggingInfoFromUrl(product.tagging.displayClick as string)
            : ''
        };
      }
    });
  });
  return results;
}

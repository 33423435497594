import { CustomSearchXStoreModule } from '../types';

/**
 * Default implementation for the {@link SearchActions.increasePageAppendingResults}.
 *
 * @param context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const increasePageAppendingCarousels: CustomSearchXStoreModule['actions']['increasePageAppendingResults'] =
  ({ commit, state }) => {
    const newPage = state.page + 1;
    if (
      newPage >= 1 &&
      state.page * state.config.carouselsNumber < state.totalResults &&
      newPage < 7
    ) {
      commit('setPage', newPage);
      commit('setIsAppendStoreResults', true);
    }
  };
